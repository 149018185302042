import React from "react";
import resumeFile from "../documents/LukeWeatherlow-Resume.pdf";

const Resume = () => {
  const educationDetails = [
    {
      yearRange: "2010 - 2013",
      title: "Bachelors in Computer Science",
      place: "Pittsburgh Art Institute",
      desc: "",
    },
    {
      yearRange: "2006 - 2008",
      title: "Associates in Graphic Design",
      place: "Erie Institute of Technology",
      desc: "",
    },
    {
      yearRange: "2006",
      title: "High School Regents Diploma",
      place: "Westfield Academy",
      desc: "",
    },
  ];

  const experienceDetails = [
    {
      yearRange: "2018 - current",
      title: "Account Director",
      place: "Werkbot Studios",
      desc: "Oversees account management team, client relationships, marketing campaigns, and ensured the successful execution of digital strategies to achieve business objectives.",
    },
    {
      yearRange: "2013 - 2018",
      title: "Sr. Web Developer",
      place: "Storey Marketing",
      desc: "Developed with expertise in front-end and back-end development, delivering high-quality and visually appealing websites while ensuring optimal functionality and user experience. Also traveled to conference and taught in-person seminars.",
    },
    {
      yearRange: "2012 - 2013",
      title: "Project Manager",
      place: "Sky Rocket Group",
      desc: "Managed all client service contracts and day to day activity as well as oversaw individual projects from initial meetings to the final launched product",
    },
    {
      yearRange: "2009 - 2012",
      title: "Project Manager",
      place: "Werkbot Studios",
      desc: "Managed team of 5 as well as oversaw individual projects from initial meetings to the final launched product. Developed websites using various content management systems.",
    },
    {
      yearRange: "2008 - 2009",
      title: "Content Specialist",
      place: "Erie Insurance",
      desc: "Designed and developed internal and external web pages as well as helped with social media strategy",
    }
  ];

  const skills = [
    {
      name: "Web Design",
      percent: 65,
    },
    {
      name: "HTML/CSS",
      percent: 95,
    },
    {
      name: "JavaScript",
      percent: 80,
    },
    {
      name: "Content Creation",
      percent: 90,
    },
    {
      name: "Google Ads",
      percent: 95,
    },
    {
      name: "Over Achiever",
      percent: 110,
    },
  ];

  return (
    <section id="resume" className="section">
      <div className="container">
        {/* Heading */}
        <p className=" text-center mb-2 wow fadeInUp">
          <span className="bg-primary text-dark px-2">Resume</span>
        </p>
        <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
          My Resume
        </h2>
        {/* Heading end*/}
        <div className="row g-5 mt-5">
          
          {/* My Experience */}
          <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.2s">
            <h2 className="text-7 fw-600 mb-4 pb-2">My Experience</h2>
            <div className="border-start border-2 border-primary ps-3">
              {experienceDetails.length > 0 &&
                experienceDetails.map((value, index) => (
                  <div key={index}>
                    <h3 className="text-5">{value.title}</h3>
                    <p className="mb-2">
                      {value.place} / {value.yearRange}
                    </p>
                    <p className="text-muted">{value.desc}</p>
                    <hr className="my-4" />
                  </div>
                ))}
            </div>
          </div>
          {/* My Education */}
          <div className="col-lg-6 wow fadeInUp">
            <h2 className="text-7 fw-600 mb-4 pb-2">My Education</h2>
            <div className="border-start border-2 border-primary ps-3">
              {educationDetails.length > 0 &&
                educationDetails.map((value, index) => (
                  <div key={index}>
                    <h3 className="text-5">{value.title}</h3>
                    <p className="mb-2">
                      {value.place} / {value.yearRange}
                    </p>
                    <p className="text-muted">{value.desc}</p>
                    <hr className="my-4" />
                  </div>
                ))}
            </div>
          </div>
        </div>
        {/* My Skills */}
        <h2 className="text-7 fw-600 mb-4 pb-2 mt-5 wow fadeInUp">My Skills</h2>
        <div className="row gx-5">
          {skills.length > 0 &&
            skills.map((skill, index) => (
              <div className="col-md-6 wow fadeInUp" key={index}>
                <p className="fw-500 text-start mb-2">
                  {skill.name}{" "}
                  <span className="float-end">{skill.percent}%</span>
                </p>
                <div className="progress progress-sm mb-4">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: skill.percent + "%" }}
                    aria-valuenow={skill.percent}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
              </div>
            ))}
        </div>
        <p className="text-center mt-5 wow fadeInUp">
          <a
            className="btn btn-outline-dark shadow-none rounded-0"
            href={resumeFile}
            download
          >
            Download CV
          </a>
        </p>
      </div>
    </section>
  );
};

export default Resume;
